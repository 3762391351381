import {
  createTheme,
  outlinedInputClasses,
  TextField,
  Theme,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingButton from "@mui/lab/LoadingButton";

function Subscribe() {
  const navigate = useNavigate();
  const outerTheme = useTheme();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const notify = (text: string) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const customTheme = (outerTheme: Theme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: "#C9C9C9",
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: "#C9C9C9",
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: "#C9C9C9",
              },
            },
          },
        },
      },
    });

  const validateEmail = (text: string) => {
    return text.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const setEmailToList = () => {
    setLoading(true);
    if (validateEmail(email)) {
      let data = JSON.stringify({ email });

      let config = {
        method: "post",
        url: "https://api.immilink.com/api/contact",
        headers: {
          "x-api-key": "1a374c6c532eabf27392fb882895edb9",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          navigate("/waitlist");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          notify(error.message);
        });
    } else {
      notify("Email not valid.");
      setLoading(false);
    }
  };

  return (
    <div className="subscribe-page">
      <span className="text main-message">Immilink is coming soon.</span>
      <p className="message-info">
        We are hard at work building the best product for you. By providing your
        email below you will receive priority access upon release.
      </p>
      <div className="form-container">
        <div className="input-group">
          <ThemeProvider theme={customTheme(outerTheme)}>
            <img
              src={"/email-icon.svg"}
              className="icon email-icon"
              alt="email icon"
            />
            <TextField
              id="email-feild"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />
            {email ? (
              <img
                src={"/close-icon.svg"}
                className="icon close-icon"
                alt="close icon"
                onClick={() => setEmail("")}
              />
            ) : null}
          </ThemeProvider>
        </div>
        <LoadingButton
          className="primary-button"
          onClick={setEmailToList}
          disabled={!email}
          loading={loading}
          loadingPosition="start"
        >
          Submit
        </LoadingButton>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Subscribe;
