function Waitlist() {
  return (
    <div className="waitlist-page">
      <img src={"/summer-sketch.svg"} className="summer-sketch" alt="summer-sketch" />
      <span className="text main-message">You have joined the waitlist!</span>
      <span className="text sub-message">We can’t wait to link up this summer!</span>
    </div>
  );
}

export default Waitlist;
