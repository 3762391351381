import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

function Landing() {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <h3>Your US Immigration & Visa Community, Assistant, and Resource</h3>
      <Button className="primary-button" variant="contained" onClick={()=> navigate('/subscribe')}>
        Join Waitlist
      </Button>
    </div>
  );
}

export default Landing;
