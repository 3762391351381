import "./App.scss";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Subscribe from "./Pages/Subscribe";
import Waitlist from "./Pages/Waitlist";
import Landing from "./Pages/Landing";
import Header from "./Layout/Header";

function App() {
  return (
    <div className="immilink">
      <Router>
        <Header />
        <section className="content">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/waitlist" element={<Waitlist />} />
          </Routes>
        </section>
      </Router>
    </div>
  );
}

export default App;
